/* COLORS */
:root {
  --color0: #121B20; 
  --color1: #1F2C32;
  --color2: #657278;
  --color3: #FFFFFF;

  --colorplus: #F15353;

  --score1: #33CE78;
  --score2: #70DBC6;
  --score3: #C2DB2E;
  --score4:#F0CE1D;
  --score5: #F7AA38;
  --score6: #F15353;}


/* CSS */
body {
  font-family: "Manrope"; 
  background-color: var(--color0);
  color: var(--color3);
  /* user-select: none; */
  display: flex;
  flex-direction: column;
  margin: 0; 
  padding: 0; 
  border: 0; 
  box-sizing: border-box;}
h1{
  font-weight: bold;
  font-size: 24px;
  color: var(--colorplus);
  font-family: "Exo 2";}
h2{
  font-size: 24px;
  color:var(--colorplus);
  font-family: "Exo 2";}
h3{
  color: var(--color3);
  font-weight: bold;
  font-size: 14px;
  padding-right: 15px;}
.reset {
  margin-left: 5%;
  font-family: inherit;
  font-weight: bold;
  background-color: var(--color1); 
  color: var(--colorplus);
  border: 1px solid var(--colorplus); 
  border-radius: 0px; 
  padding: 5px; 
  cursor: pointer;}
.reset:hover {
  background-color: var(--color1);
  color: var(--color3);}
.reset:active {
  background-color: var(--colorplus); 
  color: var(--color1);}
.filter-text{
  background-color:var(--color1);
  color:var(--color3);
  font-weight: bold;
  border-radius: 30px; 
  padding: 4px 9px; 
  display: inline-block;}

/* STYLES DE LA PAGE SETUP__________________________________________________________________ */
.setup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;}
.setup-title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  gap: 25px;}
.setup-custom-logo {
  width: auto; 
  height: 45px; 
  fill: var(--colorplus);
  margin: 0 !important;
  position: static !important;
  align-self: center !important;}
.time-filters {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;}
.general-filters{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;}
.selection-filters{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;}
.setup-go{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;}

/* Tableau de selection des filtres */
.setup-page table tr:first-child {
  background-color: var(--color1);}
.setup-page table tr:first-child td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;}
.setup-page table tr:first-child td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;}
#select-filters{
  color:var(--color2);
  font-family: inherit;
  text-align: center;
  font-size: 14px;}
#select-filter:hover{
  color:var(--color3);
  cursor: pointer;}
#select-filter:active{
  color:var(--color3);
  cursor: pointer;}
#select-filters td{
  padding: 2vh;}
.selected {
  background-color: var(--colorplus);
  color: var(--color3);}
/* Données liste */
.input-box{
  display: flex;
  flex-direction: column; 
  align-items: center; 
  text-align: center; 
  height: 100%;}
select.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: none;
  font-size: 24px;
  font-family: inherit;
  text-align: center;
  color: var(--colorplus); 
  outline: none;
  border: 1px solid transparent;
  transition: border 0.8s;}
select.custom-select option {
  font-size: 24px;
  color: var(--color3); 
  background-color: var(--color2); 
  border-radius: 0;
  border:none;}
select.custom-select:hover {
  color: var(--color3);
  cursor: pointer;}
select.custom-select:active {
  color: var(--color3);
  cursor: pointer;}
select.custom-select::-ms-expand {
  display: none;}
select.custom-select:hover::-webkit-input-placeholder {
  color: transparent;}
select.custom-select:hover::-moz-placeholder {
  color: transparent;}
select.custom-select:hover:-ms-input-placeholder {
  color: transparent;}
/* Données chiffrées */
.custom-input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none; 
  box-shadow: none;
  border: none;
  background: none;
  font-size: 24px;
  font-family: inherit;
  text-align: center;
  color: var(--colorplus); 
  outline: none;
  border: 1px solid transparent;
  transition: border 0.8s;
  border-bottom: 2px solid var(--colorplus);}
.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
  display: none;}
.custom-input2 {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none; 
  box-shadow: none;
  border: none;
  background: none;
  font-size: 24px;
  width: 150px;
  font-family: inherit;
  text-align: center;
  color: var(--colorplus); 
  outline: none;
  border: 1px solid transparent;
  transition: border 0.8s;
  border-bottom: 2px solid var(--colorplus);}
.custom-input2::-webkit-inner-spin-button,
.custom-input2::-webkit-outer-spin-button {
  display: none;}
/* Checkbox */
.custom-checkbox {
  -webkit-appearance: none;
  appearance: none; 
  width: 40px; 
  height: 40px; 
  border: 2px solid var(--colorplus); 
  background-color: var(--color2); 
  cursor: pointer;}
.custom-checkbox:checked {
  background-color: var(--colorplus);}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: translateY(50%); }
.switch input {
  opacity: 0;
  width: 0;
  height: 0;}
/* Toggle */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color3);
  -webkit-transition: .4s;
  transition: .4s;}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color2);
  -webkit-transition: .4s;
  transition: .4s;}
input:checked + .slider {
  background-color: var(--colorplus);}
input:focus + .slider {
  box-shadow: 0 0 1px var(--colorplus);}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);}
.slider.round {
  border-radius: 34px;}
.slider.round:before {
  border-radius: 50%;}
/* Explications */
.explanation{
  width: 400px;
  padding-top: 30px;}
.explanation .parentheses {
  text-decoration: underline; 
  text-decoration-color: var(--colorplus); 
  text-decoration-skip-ink: none; 
  text-underline-offset: 4px; }
/* Bouton */
.setup-button {
  font-family: inherit;
  font-weight: bold;
  background-color: var(--colorplus); 
  color: var(--color1); 
  border: 1px solid var(--color1); 
  border-radius: 30px; 
  padding: 10px 20px; 
  cursor: pointer;}
.setup-button:hover {
  background-color: var(--color3);
  color: var(--color1);}
.setup-button:active {
  background-color: var(--colorplus); 
  color: var(--color1);}

/* STYLES DE LA PAGE PLAYERS__________________________________________________________________ */

.sticky-container {
  position: sticky;
  flex-shrink: 0;
  top: 0;
  z-index: 1000;
  background-color: var(--colorplus);}
.header{
  background: linear-gradient(70deg, var(--color1), var(--color0));
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 1px var(--color0) solid;}
.selected-filters{
  background-color: var(--color0);
  font-size: small;
  padding:15px}
.title-container {
  display: flex;
  align-items: center;}
.title-container .custom-logo {
  width: 40px; 
  height: auto; 
  fill: var(--colorplus);
  padding-top: 18px;
  padding-bottom: 18px;}
.custom-logo2 {
  width: 220px; 
  height: auto; 
  fill: var(--colorplus); }
.logo{
  display: flex;
  background-color: var(--color1);
  align-items: center;
  padding-left: 25px;
  padding-right: 50px;
  border-right:1px solid var(--color2);}
.buttons-container {
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;}
.button-select {
  font-family: inherit;
  background-color: var(--color0); 
  color: var(--color2); 
  border: 1px solid var(--color2); 
  border-radius: 30px; 
  padding: 5px 15px; 
  cursor: pointer;}
.button-select:hover {
  background-color: var(--color1);
  color: var(--color3);
  border:1px solid var(--color3)}
.button-select:active {
  background-color: var(--color0);
  color: var(--colorplus);
  border:1px solid var(--colorplus)}
/* Page Players */
.players-page {
  display: flex;
  flex-direction: column; 
  height: 100%;
  overflow-x: auto;}
.players-page table {
  background-color: var(--color0);
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  color:var(--color2);
  width: 100%;}
.players-page table th {
  background-color: var(--color1);
  border: 1px solid var(--color0);
  color:var(--color2);
  padding:3px;
  font-weight: bold;}
.players-page table td{
  padding:0;
  white-space: nowrap;
  height:25px;
  line-height: 25px;
  border: 1px solid var(--color1);}
.players-page table tr{
  color: inherit;}
.players-page table tr:hover {
  background-color: rgba(255, 255, 255, 0.05); /* Blanc avec 5% d'opacité */}
.players-page table td div {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;}
/* Filtre numérique */
.table-number {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none; 
  box-shadow: none;
  border: none;
  background: none;
  width: 90%;
  font-size: 14px;
  font-family: inherit;
  text-align: center;
  color: var(--colorplus); 
  outline: none;
  border: 1px solid transparent;
  transition: border 0.8s;
  border: 1px solid var(--color3);}
  .table-number:hover {
    outline: none; 
    border-color:var(--colorplus); }
  .table-number:focus {
    outline: none; 
    border-color:var(--colorplus); }
    .table-number::-webkit-inner-spin-button,
    .table-number::-webkit-outer-spin-button {
      display: none;}
/* Filtre slider */
.slider-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre les éléments horizontalement */}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  padding:8px;
  width:100%;}
input[type="range"]::-webkit-slider-runnable-track {
  background:var(--color3);
  height: 0.2rem;}
input[type="range"]::-moz-range-track {
  background:var(--color3);
  height: 0.5rem;}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -7px; /* Centers thumb on the track */
  background-color: var(--color0);
  height: 1rem;
  width: 0.5rem;
  border:1.5px solid var(--color3);
  border-radius: 0%;}
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  background-color: var(--colorplus);
  height: 2rem;
  width: 1rem;}

/* LEAGUES */
.players-page table td:nth-child(1) {
  min-width: 160px;
  background-color: var(--color0);
  text-align: left;
  padding-left: 15px;}
/* COUNTRY */
.players-page table td:nth-child(2) {
  min-width: 154px;
  background-color: var(--color0);}
/* NAME */
.players-page table td:nth-child(3) {
  position: relative;
  min-width: 200px;
  max-width: 200px;
  text-align: left;
  padding-left: 15px;
  overflow: hidden; 
  white-space: nowrap;
  color:var(--color3);
  background-color: var(--color0);}
.players-page table td:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to left, var(--color0), transparent);
  pointer-events: none;}
/* CLUB */
.players-page table td:nth-child(4) {
  position: relative; 
  min-width: 200px;
  max-width: 200px;
  text-align: left;
  padding-left: 15px;
  overflow: hidden; 
  white-space: nowrap;}
.players-page table td:nth-child(4)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: linear-gradient(to left, var(--color0), transparent);
    pointer-events: none;}
/* POSITION */
.players-page table td:nth-child(5) {
  min-width: 80px;}
/* AGE */
.players-page table td:nth-child(6) {
  min-width: 80px;}
/* CAP */
.players-page table td:nth-child(7) {
  min-width: 80px;}
/* PLAYED */
.players-page table td:nth-child(8) {
  min-width: 90px;
  max-width: 90px;
  overflow: hidden;}
/* SO5 AVG */
.players-page table td:nth-child(9) {
  min-width: 50px;
  max-width: 50px;}
/* AA AVG */
.players-page table td:nth-child(10) {
  min-width: 50px;
  max-width: 50px;
  overflow: hidden;}
/* SO5 FREQU */
.players-page table td:nth-child(11) {
  min-width: 70px;
  max-width: 70px;
  font-size: 14px;
  overflow: hidden;}
/* AA FREQU */
.players-page table td:nth-child(12) {
  min-width: 70px;
  max-width: 70px;
  overflow: hidden;}
/* PERF INDEX */
.players-page table td:nth-child(13) {
  min-width: 60px;}
/* LIMITED */
.players-page table td:nth-child(14) {
  min-width: 80px;
  max-width: 80px;
  color:var(--score4);
  overflow: hidden;}
/* RARE */
.players-page table td:nth-child(15) {
  min-width: 80px;
  max-width: 80px;
  color:var(--score6);
  overflow: hidden;}
/* SCORES */
.players-page table td:nth-child(n+16):nth-child(-n+96) {
    min-width: 35px;
    max-width: 35px;
    overflow: hidden;}
/* SO5 Scores */
.veryhigh {
  background-color: var(--score1);
  color: var(--color0);
  font-weight: bold;}
.high {
  background-color: var(--score2);
  color: var(--color0);
  font-weight: bold;}
.medium {
  background-color: var(--score3);
  color: var(--color0);
  font-weight: bold;}
.low {
  background-color: var(--score4);
  color: var(--color0);
  font-weight: bold;}
.verylow {
  background-color: var(--score5);
  color: var(--color0);
  font-weight: bold;}
.veryverylow {
  background-color: var(--score6);
  color: var(--color0);
  font-weight: bold;}
  /* AA Scores */
.aaveryhigh {
  color: var(--score1);}
.aahigh {
  color: var(--score2);}
.aamedium {
  color: var(--score3);}
.aalow {
  color: var(--score4);}
.aaverylow {
  color: var(--score5);}
.aaveryverylow {
  color: var(--score6);}
/* LOADING SPINNER */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  z-index: 999;}
.loading-spinner > div {
  background-color: var(--color3);}
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 2px solid var(--color3);
  position: absolute;
  left: 0;
  top: 0;
  animation: rotationBreak 3s ease-in-out infinite alternate;
}
.loader::after {
  border-color: var(--colorplus);
  animation-direction: alternate-reverse;
}

@keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
    

/* FOOTER */

.footer{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 17px;
  background: linear-gradient(70deg, var(--color1), var(--color0));}
.button-paginate {
  font-size: 12px;
  padding: 0px;
  border-radius: 100%;
  padding: 5px;
  align-items: center;
  display: flex;
  background-color: var(--color0); 
  color: var(--color2); 
  border: 1px solid var(--color2); 
  cursor: pointer;}
.button-paginate:hover {
  background-color: var(--color0);
  color: var(--color3);
  border:1px solid var(--color3)}
.button-paginate:active {
  background-color: var(--color0);
  color: var(--colorplus);
  border:1px solid var(--colorplus)}

/* POURCENTAGES */
.bar-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25px; /* Spécifiez une hauteur fixe */
  overflow: hidden;}
.percentage-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color1);
  z-index: 1;}
.percentage-text {
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;}

/* SNAPSHOT */
.cloned-table-for-capture th,
.cloned-table-for-capture td {
  white-space: normal;}
.cloned-table-for-capture th input {
  display: none; /* Cela cache les inputs dans les entêtes */}
.hidden-on-image {
  display: none !important;}
.capture-header,
.capture-footer {
  width: 100%;
  color: var(--colorplus);
  text-align: center;
  background-color: var(--color0);
  padding: 15px;}
.margin-wrapper {
padding: 30px;
  background-color: var(--color0);}
.cloned-table-for-capture th:nth-child(n+5):nth-child(-n+12),
.cloned-table-for-capture td:nth-child(n+5):nth-child(-n+12) {
  max-width: 50px !important; 
  min-width: 50px !important;}
.cloned-table-for-capture th:nth-child(n+14):nth-child(-n+15),
.cloned-table-for-capture td:nth-child(n+14):nth-child(-n+15) {
  max-width: 60px !important; 
  min-width: 60px !important;}
.cloned-table-for-capture th:nth-child(n+16):nth-child(-n+96),
.cloned-table-for-capture td:nth-child(n+16):nth-child(-n+96) {
  max-width: 35px !important; 
  min-width: 35px !important;}
  
  

